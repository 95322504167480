  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-25-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert inondation Marseille</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Votre Expert après sinistre Inondation à Marseille</h1>

<p>Vous avez subi un sinistre inondation ? Votre assurance tarde à vous indemniser ou vous n’êtes pas satisfait du montant indemnitaire ?</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert inondation Marseille" />
</div>


<p>Nos experts du cabinet Expertise, neutres et indépendants, interviennent sur site après sinistre pour évaluer vos dommages et vous accompagner dans vos démarches d’indemnisation. </p>
<p>Les experts du cabinet interviennent pour défendre vos intérêts.</p>

<p>Nous vous orientons également vers les solutions techniques réparatoires adaptées à la vulnérabilité de votre et afin d’assurer votre sécurité.</p>

<p>Nos Experts vous accompagnent également pour le montage de demande de subventions auprès des services de l’État.</p>


<p> </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert après sinistre inondation</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-inondation-marseille/' className='active'>Expert inondation Marseille</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details